import {lazy} from "react"
import {checkPassReset} from '../utility/Constants'
import { store } from '../../../redux/storeConfig/store'

export const Routes = [
    {
        path: "/verify-user",
        component: lazy(() => import("../views/auth/VerifyEmail")),
        layout: "BlankLayout",
        meta: {
            general: true
        }
    },
    {
        path: "/mobile/callbacks",
         component: lazy(() => import("../views/auth/mobile-callbacks")),
        layout: "BlankLayout",
        meta:  {
            authRoute: true
        }
    },
    {
        path: "/google/login/mobile",
         component: lazy(() => import("../views/auth/google-mobile")),
        layout: "BlankLayout",
        meta:  {
            authRoute: true
        }
    },
    {
        path: "/paypal/payment/mobile",
         component: lazy(() => import("../views/auth/paypal-mobile")),
        layout: "BlankLayout",
        meta:  {
            authRoute: true
        }
    },
    {
        path: "/tiktok/callback/",
         component: lazy(() => import("../views/tiktok")),
        layout: "BlankLayout",
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        } 
    },
    {
        path: "/oauth",
        component: lazy(() => import("../views/auth/OauthLoading")),
        layout: "BlankLayout",
        meta: {
            authRoute: true
        }
    },
    {
        path: "/login",
        component: lazy(() => import("../views/auth/Login")),
        layout: "BlankLayout",
        meta: {
            authRoute: true
        }
    },
    {
        path: "/forgot-password",
        component: lazy(() => import("../views/auth/ForgotPassword")),
        layout: "BlankLayout",
        meta: {
            authRoute: true
        }
    },
    {
        path: "/user/reset-password",
        component: lazy(() => import("../views/auth/ForgetPasswordReset")),
        layout: "BlankLayout",
        meta: {
            authRoute: true
        }
    },
    {
        path: "/expired/password/reset",
        component: lazy(() => import("../views/auth/ExpiredReset")),
        layout: "BlankLayout",
        meta: {
            authRoute: true
        }
    },
    {
        path: "/complete/oauth",
        component: lazy(() => import("../views/auth/OauthComplete")),
        layout: "BlankLayout",
        meta: {
            authRoute: true
        }
    },
    {
        path: "/reset-password",
        component: lazy(() => import("../views/auth/ResetPassword")),
        layout: "BlankLayout",
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        },
        beforeEnter: checkPassReset // Add the middleware here
    },
    {
        path: "/register",
        component: lazy(() => import("../views/auth/Register")),
        layout: "BlankLayout",
        meta: {
            authRoute: true
        }
    },
    {
        path: "/dashboard",
        component: lazy(() => import("../views/dashboard")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            general: true
        }
    },
    {
        path: "/security",
        component: lazy(() => import("../views/security")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    //not found
    {
        path: "/not-found",
        component: lazy(() => import("../views/not-found")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    {
        path: "/campaigns/list",
        component: lazy(() => import("../views/campaigns-list")),
        layout: 'HorizontalLayout',
        className: 'ecommerce-application',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    {
        path: "/campaigns/:campaignId",
        component: lazy(() => import("../views/campaign-view")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    {
        path: "/preview/campaigns/:campaignId",
        component: lazy(() => import("../views/campaign-view")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    {
        path: "/my-campaigns",
        component: lazy(() => import("../views/my-campaigns")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    {
        path: "/withdrawal/list",
        component: lazy(() => import("../views/withdrawal")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    {
        path: "/withdrawal/:quote",
        component: lazy(() => import("../views/withdrawal-view")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    {
        path: "/express/checkout",
        component: lazy(() => import("../views/checkout")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "CURATOR_PERMISSION"
        }
    },
    {
        path: "/invoices/list",
        component: lazy(() => import("../views/invoices-list")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_PERMISSION"
        }
    },
    {
        path: "/invoices/:id",
        component: lazy(() => import("../views/invoices-view")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_PERMISSION"
        }
    },
    {
        path: "/payouts/list",
        component: lazy(() => import("../views/payouts-list")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    {
        path: "/payouts/:id",
        component: lazy(() => import("../views/payouts-view")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    {
        path: "/connections",
        component: lazy(() => import("../views/dashboard")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    {
        path: "/account",
        component: lazy(() => import("../views/user-account")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    {
        path: "/forms",
        component: lazy(() => import("../views/forms")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    {
        path: "/tickets",
        component: lazy(() => import("../views/tickets")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    {
        path: "/my-ticket/details/:ticketId",
        component: lazy(() => import("../views/tickets/TicketDetails")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    {
        path: "/express/my/playlists/list",
        component: lazy(() => import("../views/express-playlists-list")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "CURATOR_PERMISSION"
        }
    },
    {
        path: "/connection/list",
        component: lazy(() => import("../views/connections")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "CURATOR_PERMISSION"
        }
    },
    {
        path: "/tiktok/connection/list",
        component: lazy(() => import("../views/tiktok-connections")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "INFLUENCER_PERMISSION"
        }
    },
    {
        path: "/my-custom/campaigns/list",
        component: lazy(() => import("../views/campaigns-list/curator")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    ///artist routes
    {
        path: "/express/campaigns/list",
        component: lazy(() => import("../views/express-campaigns-list")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    {
        path: "/tiktok/express/campaigns/list",
        component: lazy(() => import("../views/tiktok-express-campaigns-list")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    {
        path: "/instagram/express/campaigns/list",
        component: lazy(() => import("../views/instagram-express-campaigns-list")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    {
        path: "/custom/campaigns/list",
        component: lazy(() => import("../views/campaigns-list/component")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    {
        path: "/express-campaigns/:campaignId",
        component: lazy(() => import("../views/express-campaigns-view")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    {
        path: "/tiktok/express-campaigns/:campaignId",
        component: lazy(() => import("../views/tiktok-express-campaigns-view")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    {
        path: "/instagram/express-campaigns/:campaignId",
        component: lazy(() => import("../views/instagram-express-campaigns-view")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
    {
        path: "/select/platform",
        component: lazy(() => import("../../influencers/views/campaigns-list/create-campaigns-artist")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_PERMISSION"
        }
    },
    {
        path: "/create/express/campaign",
        component: lazy(() => import("../views/create-express-campaign")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_PERMISSION"
        }
    },
    {
        path: "/create/tiktok/express/campaign",
        component: lazy(() => import("../views/create-tiktok-express-campaign")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_PERMISSION"
        }
    },
    {
        path: "/create/instagram/express/campaign",
        component: lazy(() => import("../views/instagram-create-express-campaign")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_PERMISSION"
        }
    },
    {
        path: "/renew/express/campaign",
        component: lazy(() => import("../views/create-express-campaign/components/RenewExpressCampaign")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_PERMISSION"
        }
    },
    {
        path: "/tiktok/renew/express/campaign",
        component: lazy(() => import("../views/create-tiktok-express-campaign/components/RenewExpressCampaign")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_PERMISSION"
        }
    },
    {
        path: "/create/tiktok/released/campaign",
        component: lazy(() => import("../views/create-tiktok-campaign/released")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_PERMISSION"
        }
    },
    {
        path: "/spotify/platform",
        component: lazy(() => import("../../influencers/views/campaigns-list/create-campaigns-artist/spotify")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_PERMISSION"
        }
    },
    {
        path: "/custom/spotify/campaign/create",
        component: lazy(() => import("../../influencers/views/campaigns-list/CustomCampaignWizardPage")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_PERMISSION"
        }
    },
    {
        path: "/tiktok/platform",
        component: lazy(() => import("../../influencers/views/campaigns-list/create-campaigns-artist/tiktok-platform")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_PERMISSION"
        }
    },
    {
        path: "/tiktok/platform",
        component: lazy(() => import("../../influencers/views/campaigns-list/create-campaigns-artist/tiktok")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_PERMISSION"
        }
    },
    {
        path: "/express/my/playlist/view/:id",
        component: lazy(() => import("../views/express-playlist-view")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "CURATOR_PERMISSION"
        }
     },
    {
        path: "/express/my/playlist/create",
        //path: "/express/my/playlist/create/:spotifyAccountId",
        component: lazy(() => import("../views/express-playlists-list/components/create-playlist/CreatePlayList")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "CURATOR_PERMISSION"
        }
    },
    {
        path: "/fan-finder/artist",
        component: lazy(() => import("../views/fan-finder")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_PERMISSION"
        }
    },
    {
        path: "/fan-finder/connection",
        component: lazy(() => import("../views/fan-finder-connection")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_PERMISSION"
        }
    },
    {
        path: "/express/callback",
        component: lazy(() => import("../views/spotify")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "CURATOR_PERMISSION"
        }
     },
     {
        path: "/paypal/callback",
        component: lazy(() => import("../views/paypal")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_PERMISSION"
        }
    },
    {
        path: "/wallet/user/transaction",
        component: lazy(() => import("../views/wallet-transactions")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_ALL_PERMISSION"
        }
    },
   
    {
        path: "/wallet/coins/packages",
        component: lazy(() => import("../views/wallet-coins")),
        layout: 'HorizontalLayout',
        verifiedAttr: 'verified',
        meta: {
            action: "call",
            resource: "ARTIST_PERMISSION"
        }
    },
    //  {
    //      path: "/tiktok/callback/",
    //      component: lazy(() => import("../views/tiktok")),
    //      layout: 'HorizontalLayout',
    //      verifiedAttr: 'verified',
    //      meta: {
    //         action: "call",
    //         // resource: "INFLUENCER_PERMISSION"
    //         resource: "ARTIST_ALL_PERMISSION"
    //     }
    //   },
      {
          path: "/instagram/oauth/",
          component: lazy(() => import("../views/instagram")),
          layout: 'HorizontalLayout',
          verifiedAttr: 'verified',
          meta: {
             action: "call",
            //  resource: "INFLUENCER_PERMISSION"
             resource: "ARTIST_ALL_PERMISSION"
         }
       },
       {
           path: "/instagram/embed/",
           component: lazy(() => import("../views/instagram/embed")),
           layout: 'HorizontalLayout',
           verifiedAttr: 'verified',
           meta: {
              action: "call",
             //  resource: "INFLUENCER_PERMISSION"
              resource: "ARTIST_ALL_PERMISSION"
          }
        }
      

]
